<template>
  <div class="funnel-container" draggable="false">
    <div class="funnel_box">
      <div class="mainarrow_box">
        <div class="arrow_body">
          <span class="main_title">应用启动</span>
        </div>
        <div class="arrow_header">
          <div class="center_arrow"><a-icon type="right" /></div>
        </div>
        <div class="arrow_body">
          <span class="main_title">获取配置</span>
        </div>
        <div class="arrow_header">
          <div class="center_arrow"><a-icon type="right" /></div>
        </div>
        <div class="arrow_body">
          <span class="main_title">流量请求</span>
          <PointArrow
            :title="'流量填充率'"
            :value="(funnelData.requestFilledRate ? funnelData.requestFilledRate : 0) + '%'"
            :is-top="true"
          />
        </div>
        <div class="arrow_header">
          <div class="center_arrow"><a-icon type="right" /></div>
        </div>
        <div class="arrow_body">
          <span class="main_title">流量填充</span
          ><PointArrow
            :title="'广告触发率'"
            :value="(funnelData.advTriggerRate ? funnelData.advTriggerRate : 0) + '%'"
            :is-top="false"
          />
        </div>
        <div class="arrow_header">
          <div class="center_arrow"><a-icon type="right" /></div>
        </div>
        <div class="arrow_body">
          <span class="main_title">触发展示</span>
          <PointArrow
            :title="'展示成功率'"
            :value="(funnelData.impressSuccessRate ? funnelData.impressSuccessRate : 0) + '%'"
            :is-top="true"
          />
        </div>
        <div class="arrow_header">
          <div class="center_arrow"><a-icon type="right" /></div>
        </div>
        <div class="arrow_body">
          <span class="main_title">展示</span>
          <PointArrow
            v-if="hasImpress"
            :title="'展示GAP'"
            :value="(funnelData.impressionGap ? funnelData.impressionGap : 0) + '%'"
            :is-top="false"
          />
          <PointArrow
            :is-jump="hasImpress"
            :title="'点击率'"
            :value="(funnelData.clickRate ? funnelData.clickRate : 0) + '%'"
            :is-top="hasImpress"
          />
        </div>
        <div v-if="hasImpress" class="arrow_header">
          <div class="center_arrow"><a-icon type="right" /></div>
        </div>
        <div v-if="hasImpress" class="arrow_body">
          <span class="main_title">展示API</span>
        </div>
        <div class="arrow_header">
          <div class="center_arrow"><a-icon type="right" /></div>
        </div>
        <div class="arrow_body">
          <span class="main_title">点击</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PointArrow from './pointArrow.vue'
export default {
  components: { PointArrow },
  props: {
    funnelData: {
      default: () => ({
        requestFilledRate: '0',
        advTriggerRate: '0',
        triggerImpress: '0',
        impressionGap: '0',
        clickRate: '0'
      }),
      type: Object
    },
    hasImpress: {
      default: true,
      type: Boolean
    }
  }
}
</script>

<style lang="less" scoped>
.funnel-container {
  background: #fff;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  .funnel_box {
    position: relative;
    width: 93%;
    height: 90%;
    display: flex;
    align-items: center;
    .mainarrow_box {
      position: relative;
      height: 15%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow_body,
      .arrow_header {
        height: 80%;
      }
      .arrow_body {
        position: relative;
        border: 1px solid @primary-color;
        border-radius: 20px;
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(234, 239, 255, 1);
        span.main_title {
          font-size: 16px;
          color: @primary-color;
        }
      }
      .arrow_header {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .center_arrow {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: fade(@primary-color,  60%);
          position: relative;
          text-align: center;
          line-height: 20px;
          font-weight: 900;
          color: #fff;
          z-index: 3;
          font-size: 12px;
        }
        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          border-top: 2px solid rgba(230, 230, 230, 1);
          position: absolute;
        }
      }
    }
  }
}
</style>
